import React, {useEffect, useState} from "react"
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga";

import closeImg from "../../images/getcode/close.svg"
import {generateNSIPPromoCode, getMailChimpEndPoint} from "../../utils";
import Axios from "axios";

export function GetCode1({ close }) {
  const [emailValue, setEmailValue] = useState("")
  const [mailChimpEndPoint, setMailChimpEndPoint] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [cookies, setCookie] = useCookies(['mainPagePopup']);

  useEffect(() => {
    setCookie("mainPagePopup", true);
    setMailChimpEndPoint(getMailChimpEndPoint());

  },[])
  const handleOnChange = e => {
    setErrorMsg("")
    setEmailValue(e.target.value)
  }

  const handleEmail = e => {
    e.preventDefault()
    if (!emailValue){
      setErrorMsg("Email is Required");
      return
    }
    setErrorMsg("")
    setSubmitting(true)
    const promoCode = generateNSIPPromoCode();
    if (typeof window !== "undefined") {
      Axios.post(mailChimpEndPoint, {
        email: emailValue,
        audienceId: "9c2b2b3fd3",
        tags: ["NSIP"],
        mergeFields: {
          EMAIL: emailValue,
          PROMOTAG: promoCode,
          ENTERYOUR: "null"
        },
      })
          .then(function(response) {
            console.log(response)
            if (response) {
              if (response.data.success) {
                ReactGA.event({
                  category: 'POP-UP',
                  action: 'Click on Main Page Generate code'
                });
                window.location.replace(
                    `/nsip?email=${emailValue}&promo_code=${promoCode}`
                )
                setSubmitting(false)
              } else {
                if (response.data.message) {
                  console.log(response.data.message)
                  setErrorMsg(response.data.message.split(". Use")[0])
                  setSubmitting(false)
                }
              }
            }
          })
          .catch(function(error) {
            setErrorMsg("Something went wrong, Please Try Again!");
            setSubmitting(false)
          })
    }
  }

  const closePopup = () => {
    ReactGA.event({
      category: 'POP-UP',
      action: 'Close Main Page Pop-up without code'
    });
    close();
  }

  return (
    <div className="get-code ">
      <div className="code1 mx-auto relative">
        <img
          src={closeImg}
          alt="close"
          className="close-icon absolute top-0 right-0"
          onClick={() => closePopup()}
        />
        <div className={`${errorMsg && "error"} cta flex flex-col items-center`}>
          <div className="flex">
            <input
              type="text"
              className="email focus:outline-none"
              placeholder=" Enter email address"
              value={emailValue}
              onChange={handleOnChange}
            />
            <button className="btn-left focus:outline-none" onClick={handleEmail} disabled={submitting}>
              GET CODE
            </button>
          </div>
          {errorMsg && (
              <div className=" text-red-600 mt-1 text-left">{errorMsg}</div>
          )}
        </div>
      </div>
    </div>
  )
}
export function GetCode2({ close }) {
  const [emailValue, setEmailValue] = useState("")
  const [cookies, setCookie] = useCookies(['pricingPagePopup']);
  const [mailChimpEndPoint, setMailChimpEndPoint] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    setCookie("pricingPagePopup", true);
    setMailChimpEndPoint(getMailChimpEndPoint());
  },[])

  const handleOnChange = e => {
    setErrorMsg("")
    setEmailValue(e.target.value)
  }

  const handleEmail = e => {
    e.preventDefault()
    if (!emailValue){
      setErrorMsg("Email is Required");
      return
    }
    setErrorMsg("")
    setSubmitting(true)
    const promoCode = generateNSIPPromoCode();
    if (typeof window !== "undefined") {
      Axios.post(mailChimpEndPoint, {
        email: emailValue,
        audienceId: "9c2b2b3fd3",
        tags: ["NSIP"],
        mergeFields: {
          EMAIL: emailValue,
          PROMOTAG: promoCode,
          ENTERYOUR: "null"
        },
      })
          .then(function(response) {
            console.log(response)
            if (response) {
              if (response.data.success) {
                ReactGA.event({
                  category: 'POP-UP',
                  action: 'Click on Pricing Page Generate code'
                });
                const promo_code = "code124"
                window.location.replace(
                    `/nsip?email=${emailValue}&promo_code=${promoCode}`
                )
                setSubmitting(false)
              } else {
                if (response.data.message) {
                  console.log(response.data.message)
                  setErrorMsg(response.data.message.split(". Use")[0])
                  setSubmitting(false)
                }
              }
            }
          })
          .catch(function(error) {
            setErrorMsg("Something went wrong, Please Try Again!");
            setSubmitting(false)
          })
    }
  }

  const closePopup = () => {
    ReactGA.event({
      category: 'POP-UP',
      action: 'Close Pricing Page Pop-up without code'
    });
    close();
  }
  return (
    <div className="get-code ">
      <div className="code2 mx-auto relative">
        <img
          src={closeImg}
          alt="close"
          className="close-icon absolute top-0 right-0"
          onClick={() => closePopup()}
        />
        <div className={`${errorMsg && "error"} cta flex flex-col items-center`}>
          <div className="flex">
            <input
                type="text"
                className="email focus:outline-none"
                placeholder=" Enter email address"
                value={emailValue}
                onChange={handleOnChange}
            />
            <button className="btn-left focus:outline-none" onClick={handleEmail}  disabled={submitting}>
              GET CODE
            </button>
          </div>
          {errorMsg && (
              <div className=" text-red-600 mt-1 text-left">{errorMsg}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export function GetCode3({ close }) {
  const [emailValue, setEmailValue] = useState("")
  const [cookies, setCookie] = useCookies(['signupPagePopup']);
  const [mailChimpEndPoint, setMailChimpEndPoint] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    setCookie("signupPagePopup", true);
    setMailChimpEndPoint(getMailChimpEndPoint());
  },[])

  const handleOnChange = e => {
    setErrorMsg("")
    setEmailValue(e.target.value)
  }

  const closePopup = () => {
    ReactGA.event({
      category: 'POP-UP',
      action: 'Close Sign up Page Pop-up without code'
    });
    close();
  }

  const handleEmail = e => {
    e.preventDefault()
    if (!emailValue){
      setErrorMsg("Email is Required");
      return
    }
    setErrorMsg("")
    setSubmitting(true)
    const promoCode = generateNSIPPromoCode();
    if (typeof window !== "undefined") {
      Axios.post(mailChimpEndPoint, {
        email: emailValue,
        audienceId: "9c2b2b3fd3",
        tags: ["NSIP"],
        mergeFields: {
          EMAIL: emailValue,
          PROMOTAG: promoCode,
          ENTERYOUR: "null"
        },
      })
          .then(function(response) {
            console.log(response)
            if (response) {
              if (response.data.success) {
                ReactGA.event({
                  category: 'POP-UP',
                  action: 'Click on Sign up Page Generate code'
                });
                window.location.replace(
                    `/nsip?email=${emailValue}&promo_code=${promoCode}`
                )
                setSubmitting(false)
              } else {
                if (response.data.message) {
                  console.log(response.data.message)
                  setErrorMsg(response.data.message.split(". Use")[0])
                  setSubmitting(false)
                }
              }
            }
          })
          .catch(function(error) {
            setErrorMsg("Something went wrong, Please Try Again!");
            setSubmitting(false)
          })
    }
  }
  return (
      <div className="get-code">
        <div className="code3 mx-auto relative">
          <img
              src={closeImg}
              alt="close"
              className="close-icon absolute top-0 right-0"
              onClick={() => closePopup()}
          />

          <div className={`${errorMsg && "error"} cta flex flex-col items-center`}>
            <div className="flex">
              <input
                  type="text"
                  className="email focus:outline-none"
                  placeholder=" Enter email address"
                  value={emailValue}
                  onChange={handleOnChange}
              />
              <button className="btn-left focus:outline-none" onClick={handleEmail} disabled={submitting}>
                GET CODE
              </button>
            </div>
            {errorMsg && (
                <div className=" text-red-600 mt-1 text-left">{errorMsg}</div>
            )}
          </div>
        </div>
      </div>
  )
}
