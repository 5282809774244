import React, { useState, useEffect } from "react"
import { createPortal } from "react-dom"

const Modal = ({ isOpen, hide, children }) => {
  console.log(isOpen, hide, children)
  function handleOverlayClicked(e) {
    if (e.target.className !== "luca_popup__modal-wrapper") {
      return
    }
    // hide()
  }

  return (
    isOpen &&
    createPortal(
      <React.Fragment>
        <div className="luca_popup__modal-overlay" />
        <div
          className="luca_popup__modal-wrapper"
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
          onClick={handleOverlayClicked}
        >
          {children}
        </div>
      </React.Fragment>,
      document.body
    )
  )
}

const Popup = () => {}
Popup.Modal = Modal
export default Popup

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
  }

  function handleKeyDown(event) {
    if (event.keyCode !== 27) return
    toggle()
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown)
    }

    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [isOpen])

  return [{ isOpen, hide: toggle }, toggle]
}
